export const pliosEndpoint = "/plios/",
  duplicateEndpoint = "/duplicate/",
  copyEndpoint = "/copy/",
  bulkDeleteEndpoint = "bulk_delete/",
  plioDataDumpEndpoint = "/download_data/",
  plioMetricsEndpoint = "/metrics/",
  itemsEndpoint = "/items/",
  videosEndpoint = "/videos/",
  questionsEndpoint = "/questions/",
  sessionsEndpoint = "/sessions/",
  sessionAnswersEndpoint = "/session-answers/",
  eventsEndpoint = "/events/",
  otpRequestEndpoint = "/otp/request/",
  otpVerifyEndpoint = "/otp/verify/",
  userFromTokenEndpoint = "/users/token/",
  usersEndpoint = "/users/",
  organizationsEndpoint = "/organizations/",
  userConfigEndpoint = "/config/",
  convertTokenEndpoint = "/convert-token/",
  refreshTokenEndpoint = "/token/",
  imagesEndpoint = "/images/",
  externalAuthTokenEndpoint = "/generate-external-auth-access-token/",
  settingsEndpoint = "/setting";
